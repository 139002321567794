import "./Feed.css";

import { styled } from '@mui/material/styles';
import { Post } from "./Post";
import { usePost, useSubreddit } from "../../hooks/useSubreddit";
import PostType from "../../Types/PostType";
import { useQueries } from "../../hooks/useQueries";
import { useEffect, useState } from "react";
import { FiShare } from "react-icons/fi";
import { FaSadCry } from "react-icons/fa";
import { hasMaga, isMobile } from "../../Util";
import FeedSkeley from "./FeedSkeley";
import DesktopPost from "./DesktopPost";
import { useImoLog } from "../Providers/ImoLog/ImoLogProvider";

interface FeedProps {
    subreddit: string,
    sort: string,
    range: string,
    onPostOpen: (post: PostType) => void,
    onUserSelected?: (user: string) => void,
}

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#000000",
    color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
  }));

export const Feed = ({subreddit, sort, onPostOpen, onUserSelected, range}: FeedProps) => {

    const fetchSub = useSubreddit(subreddit, sort, range);
    const [styleOverride, setStyleOverride] = useState<string | null>(localStorage.getItem("postStyle"));
    const demaga = localStorage.getItem("demaga") === "true";
    const { addLog } = useImoLog();

    useEffect(() => {
        if (!isMobile()) window.addEventListener("keydown", bindKeys);
        return () => {
            if (!isMobile()) window.removeEventListener("keydown", bindKeys);
        }
    }, [styleOverride]);


    // Query Params
    // Automatically open post passed in 'r' query params
    const rQuery = useQueries("r");
    const rPath = window.location.pathname.length > 1 ? window.location.pathname : undefined;
    const rPost = usePost(rQuery || rPath || "", rQuery !== null || rPath !== undefined);
    useEffect(() => {
        if (rPost.isFetched && rPost.data?.data[0]?.data?.children[0]) {
            onPostOpen(rPost.data?.data[0]?.data?.children[0]);
        }
    }, [rPost.isFetched, rPost.data]);


    useEffect(() => {
        if (!fetchSub.isFetched || fetchSub.isLoading || fetchSub.isFetching) return;
        // @ts-ignore
        const is_nsfw = fetchSub.data?.data?.data?.children[0]?.data?.over_18;
        if (localStorage.getItem("rememberLastSubreddit") === "true" ) {
        // @ts-ignore
            if (!(is_nsfw && localStorage.getItem("disableRemNsfw") === "true") && fetchSub.data && fetchSub.data?.data?.data?.children.length > 0) {
                localStorage.setItem("subreddit", subreddit);
                addLog({
                    type: "info",
                    message: `Subreddit ${subreddit} loaded`,
                });
            }
        }
        // scroll post container to the top
        const postContainer = document.getElementById("feed");
        if (postContainer) {
            postContainer.scrollTo(0, 0);
        }
        console.log(fetchSub.data);
      }, [subreddit, fetchSub.isFetched, fetchSub.isLoading, fetchSub.isFetching]);


    const bindKeys = (e: KeyboardEvent) => {
        if (e.key === "Shift") {
            const oldStyle = styleOverride + "";
            const newStyle = styleOverride === "card" || styleOverride === "auto" ? "list" : "card";
            console.log(oldStyle, newStyle);
            setStyleOverride(newStyle);
        }
    }

    
    const mobilePosts = () => {
        // @ts-ignore
        return fetchSub.data?.data?.data.children.map((post: PostType) => {
            if (demaga && hasMaga(post.data?.title)) return null;
            return (
                <Post 
                    post={post}
                    onPostClick={(post: PostType) => {onPostOpen(post)}}
                    onUserSelected={onUserSelected}
                    key={`post_container_${post.data.id}`}
                />
            );
        });
    }

    const desktopPosts = () => {
        // @ts-ignore
        return fetchSub.data?.data?.data.children.map((post: PostType) => {
            if (demaga && hasMaga(post.data?.title)) return null;
            return (
                <DesktopPost 
                    post={post}
                    onPostClick={(post: PostType) => {onPostOpen(post)}}
                    onUserSelected={onUserSelected}
                    key={`post_container_${post.data.id}`}
                />
            );
        });
    }



    const renderFeed = () => {
        let posts; 
        if (styleOverride === "card") {
            posts = desktopPosts();
        } else if (styleOverride === "list") {
            posts = mobilePosts();
        } else {
            posts = isMobile() ? mobilePosts() : desktopPosts();
        }

        return (
            <div>
                <div className="postsContainer">
                    {
                        window.matchMedia('(display-mode: standalone)').matches === false && isMobile() &&
                        <div className="pwaBanner" key="pwaBanner">
                            <div className="pwaBannerText">
                            <span>imotbo is a PWA, which means it can be installed on your device and used like a native app.</span><br/><br/>
                                <span>Tap the <FiShare /> button and select "Add to Home Screen"</span><br/><br/>
                                {navigator.canShare && navigator.canShare() 
                                    && 
                                    <div  className={"pwaInstallBtn"} onClick={() => {navigator.share()}} >
                                        <span>or click here and select "Add to Home Screen"</span>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {posts || renderNoData()}
                </div>
            </div>
        );
    }

    const renderNoData = () => {
        return (
            <div className="noData">
                <FaSadCry className="noDataIcon" />
                <span>No posts found</span>
            </div>
        )
    }

    const renderLoading = () => {
        return (
            <div className="skeleys">
                {
                    Array.from(Array(10).keys()).map((i) => <FeedSkeley key={`skeley_${i}`} />)
                }
            </div>
        );
    }

    const renderError = () => {
        
        return (
            <div>Error</div>
        );
    }

    return (
        <Root id="feed">
            {
                fetchSub.isFetching ? renderLoading() : fetchSub.isError ? renderError() : renderFeed()
            }
        </Root>
    )
}