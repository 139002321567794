import "./Header.css";
import { AppBar, Badge, Dialog, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Select, Slide, Switch, Toolbar, Typography } from "@mui/material"
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { MdClose, MdOutlineArrowBackIosNew } from "react-icons/md";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { GiSettingsKnobs } from "react-icons/gi";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { BsInfo } from "react-icons/bs";
import { VERSION } from "../../Constants";
import LogViewer from "../LogViewer/LogViewer";


interface HeaderProps {
    onBrandClick: () => void;
    onSubredditChange: (subreddit: string) => void;
    // This is not good, but it works
    onDarkmodeChange: (mode: boolean) => void;
    default_sub: string;
    secondsRemaining?: number;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const stack: [string] = ["popular"]

export const Header = ({onBrandClick = () => null, onSubredditChange = () => null, default_sub, onDarkmodeChange, secondsRemaining = 0}: HeaderProps) => {
    const [subreddit, setSubreddit] = useState<string>(default_sub);
    const queryClient = useQueryClient();
    const [showLogs, setShowLogs] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [postsStyle, setPostsStyle] = useState<string>(localStorage.getItem("postStyle") || "auto");

    const [dModeSetting, setDModeSetting] = useState<boolean>(localStorage.getItem("darkMode") === "true");
    const [remSetting, setRemSetting] = useState<boolean>(localStorage.getItem("rememberLastSubreddit") === "true");
    const [nsfwSetting, setNsfwSetting] = useState<boolean>(localStorage.getItem("disableRemNsfw") === "true");
    const [useImoPlayer, setUseImoPlayer] = useState<boolean>(localStorage.getItem("useImoPlayer") === "true");
    const [demaga, setDemaga] = useState<boolean>(localStorage.getItem("demaga") === "true");

    const [appTimeLimit, setAppTimeLimit] = useState<number>(parseInt(localStorage.getItem("appTimeLimit") || "0"));

    useEffect(() => {
      onDarkmodeChange(dModeSetting);
    }, [dModeSetting]);

    useEffect(() => {
      localStorage.setItem("postStyle", postsStyle);
    }, [postsStyle])

    const settingsDialog = () => {
        return (
            <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar
                sx={{
                  paddingTop: "20px",
                  marginTop: "calc(var(--sab))"
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  aria-label="close"
                >
                    <MdClose />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  imotbo settings
                </Typography>
              </Toolbar>
            </AppBar>
            <List
            sx={{
                width: '100%',
                height: 'calc(100vh - 64px)',
                overflow: 'auto',
            }}
            >
              <ListItemButton onClick={() => {
                queryClient.clear();
                localStorage.clear();
                window.location.reload();
              }}>
                <ListItemText primary="Clear Cache" secondary="clears the query cache.  This will cause more api calls while the cache is regenerated" />
              </ListItemButton>
              <ListItem>
                <ListItemText primary="Dark Mode" secondary="Dark mode is toggled by tapping the logo.  This controls the default mode on app launch."/>
                <Switch
                    checked={dModeSetting}
                    onChange={(e) => {
                      localStorage.setItem("darkMode", localStorage.getItem("darkMode") === "true" ? "false" : "true");
                      setDModeSetting(!dModeSetting);
                    }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Remember last subreddit?" secondary="If enabled, imotbo will launch where you left off"/>
                <Switch
                    checked={remSetting}
                    onChange={(e) => {
                      localStorage.setItem("rememberLastSubreddit", localStorage.getItem("rememberLastSubreddit") === "true" ? "false" : "true"); 
                      setRemSetting(!remSetting);
                    }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Disable remembering NSFW subs" secondary="Don't launch imotbo to a NSFW sub"/>
                <Switch
                    checked={nsfwSetting}
                    onChange={(e) => {
                      localStorage.setItem("disableRemNsfw", localStorage.getItem("disableRemNsfw") === "true" ? "false" : "true"); 
                      setNsfwSetting(!nsfwSetting);
                    }}
                  />
              </ListItem>
              <ListItem>
                <ListItemText primary="Use imotbo player for reddit video" secondary="Use imotbo player instead of the default player"/>
                <Switch
                    checked={useImoPlayer}
                    onChange={(e) => {
                      localStorage.setItem("useImoPlayer", localStorage.getItem("useImoPlayer") === "true" ? "false" : "true"); 
                      setUseImoPlayer(!useImoPlayer);
                    }}
                  />
              </ListItem>
              <ListItem>
                <ListItemText primary="App Time Limit" secondary="Set a time limit for how long imotbo can be used in a day (minutes).  0 Means no limit"/>
                <input
                  type="number"
                  value={appTimeLimit}
                  onChange={(e) => {
                    setAppTimeLimit(parseInt(e.target.value));
                  }}
                  onBlur={(e) => {
                    localStorage.setItem("appTimeLimit", ""+appTimeLimit);
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="De-Maga Feed" secondary="Attempt to remove as much maga shit as possible"/>
                <Switch
                    checked={demaga}
                    onChange={(e) => {
                      localStorage.setItem("demaga", localStorage.getItem("demaga") === "true" ? "false" : "true"); 
                      setDemaga(!demaga);
                      window.location.reload();
                    }}
                  />
              </ListItem>
              <ListItem>
                <ListItemText primary="Post Style" secondary="List items (mobile default) or big cards (Desktop default)?"/>
                <Select
                  native
                  value={postsStyle}
                  style={{width: "200px"}}
                  onChange={(e) => {
                    setPostsStyle(e.target.value as string);
                    window.location.reload();
                  }}
                  variant="standard"
                >
                  <option value={"auto"}>Auto</option>
                  <option value={"list"}>List</option>
                  <option value={"card"}>Card</option>
                </Select>

              </ListItem>
              <Divider />
                  <ListItem>
                    <ListItemText primary="About" secondary="imotbo is a PWA reddit client" />
                  </ListItem>
                  <ListItemButton 
                    onClick={() => {
                      setShowLogs(!showLogs);
                    }}
                  >
                    <ListItemText primary="View Logs" secondary="Open imotbo logs for debugging" />
                  </ListItemButton>
                  {showLogs && <LogViewer />}
                  <ListItem>
                    <ListItemText primary="Version" secondary={VERSION} />
                  </ListItem>
                  <ListItemButton onClick={() => window.location.reload()}>
                    <ListItemText primary="Reload" secondary="Reload imotbo" />
                  </ListItemButton>
              <Divider />
              <ListItem>
                <ListItemText primary="User Agent" secondary={navigator.userAgent || "{No User Agent}"} />
                <BsInfo />
              </ListItem>
              <ListItem>
                <ListItemText primary="Platform" secondary={navigator.platform || "{No Platform}"} />
                <BsInfo />
              </ListItem>
              <ListItem>
                <ListItemText primary="Language" secondary={navigator.language || "{No Language}"} />
                <BsInfo />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cookies Enabled" secondary={navigator.cookieEnabled ? "Yes" : "No"} />
                <BsInfo />
              </ListItem>
              <ListItem>
                <ListItemText primary="Location" secondary={document.location.href || "{No Location}"} />
                <BsInfo />
              </ListItem>
              <ListItem>
                <ListItemText primary="Query Parameters" secondary={document.location.search || "{No Params Passed}"} />
                <BsInfo />
              </ListItem>
            </List>
          </Dialog>
        );
    }

    return (
      <AppBar position="static" id="header">
        <div id="brand" onClick={onBrandClick}>
          <Badge
            badgeContent={secondsRemaining > 120 ? Math.floor(secondsRemaining / 60) : secondsRemaining }
            color={secondsRemaining > 60 ? "primary" : "error"}
            showZero={false}
            max={2000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
              imotbo
          </Badge>
        </div>
        <div className="nav-input">
            <div className="back-btn"
                onClick={() => {
                    const s = stack.pop() || "popular";
                    setSubreddit(s);
                    onSubredditChange(s);
                }}
            >
                <MdOutlineArrowBackIosNew style={{width: "18px", height: "18px", marginBottom: "-4px"}} />
            </div>
            <span className={"nav-input-prefix"}>/r/</span>
            <input 
                className={"nav-input-cmp"} 
                onClick={(e) => {
                    e.currentTarget.select();
                }}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        setSubreddit(subreddit.replaceAll(" ", ""));
                        onSubredditChange(subreddit.replaceAll(" ", ""));
                        stack.push(subreddit.replaceAll(" ", ""));
                        e.currentTarget.blur();
                    }
                }}
                onChange={(e) => {
                        setSubreddit(e.target.value)
                }}
                value={subreddit}
            />
            <div onClick={() => {
                setSubreddit(subreddit.replaceAll(" ", ""));
                onSubredditChange(subreddit.replaceAll(" ", ""));
                stack.push(subreddit.replaceAll(" ", ""));
            }}>
                <TbCircleArrowRightFilled style={{width: "20px", height: "20px", marginBottom: "-4px"}} />
            </div>
        </div>

        <div>
            <div onClick={() => {
                setOpen(true);
            }}>
                <GiSettingsKnobs style={{width: "16px", height: "16px", marginBottom: "-4px"}} />
            </div>
        </div>
        {settingsDialog()}
      </AppBar>
    )
}